import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60a8aa8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["read-only"]
const _hoisted_2 = { class: "flex items-center px-2" }
const _hoisted_3 = ["onClick", "checked", "disabled"]
const _hoisted_4 = { class: "group" }
const _hoisted_5 = ["id", "value", "disabled"]
const _hoisted_6 = ["for"]
const _hoisted_7 = ["id", "value", "disabled"]
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "group" }
const _hoisted_10 = ["checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", { "read-only": _ctx.readOnly }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.filter(e => e.items), (group, groupIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `check-box-list-group-${groupIndex}`,
        class: "bg-gray-400 border rounded mb-1"
      }, [
        _createElementVNode("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(group.caption || group.name) + "   ", 1),
          _createElementVNode("input", {
            type: "checkbox",
            onClick: ($event: any) => (_ctx.onSelectAllButtonClick(group.name)),
            checked: _ctx.itemCount[group.name] == _ctx.selectedCount[group.name],
            disabled: _ctx.readOnly
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item, itemIndex) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `check-box-list-group-item-${itemIndex}`,
              style: _normalizeStyle({ width: _ctx.itemWidthPercentage })
            }, [
              _createElementVNode("div", null, [
                _withDirectives(_createElementVNode("input", {
                  id: `check-box-list-group-item-checkbox-${item.value}`,
                  type: "checkbox",
                  value: item.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValues) = $event)),
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChange())),
                  disabled: _ctx.readOnly
                }, null, 40, _hoisted_5), [
                  [_vModelCheckbox, _ctx.selectedValues]
                ]),
                _createElementVNode("label", {
                  for: `check-box-list-group-item-checkbox-${item.value}`
                }, _toDisplayString(item.name), 9, _hoisted_6)
              ])
            ], 4))
          }), 128))
        ])
      ]))
    }), 128)),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.filter(e => e.value), (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `check-box-list-item-${index}`,
          style: _normalizeStyle({ width: _ctx.itemWidthPercentage })
        }, [
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              id: `check-box-list-group-item-checkbox-${item.value}`,
              type: "checkbox",
              value: item.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedValues) = $event)),
              onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChange())),
              disabled: _ctx.readOnly
            }, null, 40, _hoisted_7), [
              [_vModelCheckbox, _ctx.selectedValues]
            ]),
            _createElementVNode("label", {
              for: `check-box-list-group-item-checkbox-${item.value}`
            }, _toDisplayString(item.name), 9, _hoisted_8)
          ])
        ], 4))
      }), 128))
    ]),
    _createElementVNode("label", _hoisted_9, [
      _createElementVNode("input", {
        type: "checkbox",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSelectAllButtonClick('all'))),
        checked: _ctx.itemCount['all'] == _ctx.selectedCount['all'],
        disabled: _ctx.readOnly
      }, null, 8, _hoisted_10),
      _createTextVNode("  全選")
    ])
  ], 8, _hoisted_1))
}